import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderSec from '../components/AustenTable/headerSec'
import OrderSec from '../components/AustenTable/orderSec'
import ImageSec from '../components/AustenTable/imageSec'

const AustenTable = () => (
  <Layout>
    <SEO title="Austen Table" />

    <HeaderSec/>
    <OrderSec/>
    <ImageSec/>

  </Layout>
)

export default AustenTable
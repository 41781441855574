import React from 'react'
import styled from 'styled-components'

export default function Btn() {
    return (
    <>
        <BtnWrap>
      <div className="abtn">SALE PRICE</div>

          INR 8,850/-
        </BtnWrap>

    

        </>
    )
}

const BtnWrap = styled.div`

padding:1.8rem 5rem 1.2rem 5rem ;

position: relative;
background-color:#F39200;
width:80%;
color:white;
border-radius:0.8rem;
box-shadow: 0 1px 20px 1px black;
font-size:1.6rem;
font-weight:bold;
margin: 4rem auto;
text-align:center;
cursor: pointer;

.abtn{
    padding:0.6rem  1.7rem;
    position:absolute;
    color:#F39200;
    width:260px;
    margin:auto;
    top:-10%;
    left:50%;
    text-align:center;
    font-weight:bold;
    transform:translate(-50%, -50%);
    background-color:white;
    border-radius:0.5rem;
    box-shadow: 0 1px 10px 1px rgba(0,0,0,0.2);
    font-size:1.2rem;


}

@media(max-width:960px){
    padding:4rem 1rem 1rem 1rem;
margin:2rem auto;
    .abtn{
        top:-5%;

width:80%;
font-size:1rem;

    }
    


}


@media(max-width:1700px){

    width:100%;
    padding:1.8rem 5rem 1.2rem 5rem ;
    .abtn{
width:80%;
        
    }

    
}
`
import React from 'react'
import styled from 'styled-components'
import img2 from '../../images/AustenTable/Study Table Black Top View.jpg'
import OrderBtn from './orderbtn'
import SaleBtn from './salebtn'

import { FaTruckMoving, FaWrench } from "react-icons/fa";

export default function OrderSec() {
    return (
        <OrderSecWrap className="container-fluid p-0">
            <div className="row no-gutters">

                <div className="col-sm-6  d-flex align-items-center justify-content-center ">
                    <div>
                    <div className="orderContent text-light">

                      <h2>Specifications</h2>
                      <p> <b>Tabletop</b> : 25mm thick prelaminated engineering wood with PVC edge banding <br/><br/>
                      <b>Legs</b> : Powder coated Metal frame with modesty panel of size 1 feet height for privacy as well as additional support strength <br/>
                       <b>Base </b>:  Metal base with anti skid bush for levelling <br/><br/>
                      <b>Warranty : 12 months</b><br/><br/>
                       The warranty covers manufacturing/ workmanship and material defects that occur during the warranty period. The warranty applies to furniture used under normal household conditions.</p>

                   <div className="container px-sm-5">
                      <div className="row mt-sm-5">
                   
                     
                        <div className="col-md-6 s col-sm-3 p-sm-3 p-3 order-sm-3 order-4">
                            <FaWrench/>
                            <h3>Free Installation</h3>

                        </div>

                        <div className="col-md-6 col-sm-3 order-sm-2 order-1 ">

                        <SaleBtn/>
                        <h4 className="text-center text-uppercase tax">Inclusive of all Taxes </h4>
                            </div>
                        <div className="col-md-6 s col-sm-3 p-sm-3 p-3 order-sm-1 order-3">
                        <FaTruckMoving/>
                        <h3>Free Delivery in Banglore</h3>
                    </div>

                        <div className="col-md-6 col-sm-3 order-sm-4 order-2">
                           
                    <OrderBtn/>
                        </div>
                      </div>
                      </div>
                    </div>
                
              
                  
              
                   </div>
                </div>
                <div className="col-sm-6 d-sm-block d-none">
                    <img src={img2} alt="imge" width="100%"/>
                </div>

            </div>
        </OrderSecWrap>
    )
}


const OrderSecWrap = styled.div`

background-color:#000000;

.tax{

color:#F39200;
font-size:1.3rem;
font-weight:200;
text-transform:uppercase;
letter-spacing:0.03rem;
transform:translateY(-2.3rem);
}
.orderContent{
    padding:1rem 3rem;
h2{

    color:#dc6e26;
    font-size:3rem;
    text-align:left;
}
   p{
font-size:1.5rem;
line-height:1.4;
letter-spacing:0.05rem;
text-align:left;


   } 

}


@media(max-width:960px){

    .tax{

        transform:translateY(-0.9rem);
    }
.btns{

    flex-direction:column;
    transform:translateY(1rem);

}
    .orderContent{

        padding: 1rem;

       .s  h3{
        font-size:1.3rem;
        text-align:center;
    }

    p{

        font-size:1.2rem;
        text-align:center;
    } 
    }
    h2{

color:#dc6e26;
font-size: 1.8rem !important;
    text-align: center !important;
}
}


.s h1{
    font-size:1.3rem;
    padding:1rem
}
.s svg{

    color:#dc6e26;
    font-size:4rem;
    text-align:center;
    display:block;
    margin:auto;
}


 .s h3{

    text-align:center;
    color:white;
    font-size:1.6rem;

}

@media(max-width:960px){



.s svg{
  font-size:4rem;
    
}
h1{
    font-size:1.5rem;
    text-align:center;
}

.s h3{

text-align:center;
color:white;
font-size:1.3rem;

}

}
`
import React from 'react'
import styled from 'styled-components'
import imgHead from '../../images/AustenTable/blackbig.png'
export default function HeaderSec() {
    return (
        <HeaderSecWrap className="container-fluid p-0" >

            <div className="row no-gutters">

             <div className="col-sm-7">
                 <img src={imgHead} alt="headerimg" width="100%"/>
             </div>
             <div className="col-sm-5 d-flex align-items-center justify-content-center">
              <div className="content">
                 <hr className="d-sm-block d-none"/>
                 <h1 className="text-white">Austen <br/> Study Table</h1>
             </div>
            </div>
            </div>
       
        </HeaderSecWrap>
    )
}


const HeaderSecWrap = styled.div`

background-color:#000000;

.content{
hr{

    height:0.7rem;
    background-color:#dc6e26;
}
  h1{

    font-size:7rem;
    text-align:left;

}  
} 

@media(max-width:960px){

    .content{
hr{

    height:0.2rem;
    background-color:#dc6e26;
}

 h1{

    font-size:3rem;
    text-align:center;
} 

    }

    
}
`
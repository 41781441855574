import React from 'react'
import styled from 'styled-components'
export default function Dimentiontable() {


const tdata = [{

    title:"Length of table",
    value:"4 feet"
},
{

    title:"Depth of table ",
    value:"2 feet"
},
{

    title:"Height of table",
    value:"2 feet 6 inches"
},
{

    title:"Length of modesty panel ",
    value:"4 feet"
},
{

    title:"Height of modesty panel",
    value:"1 feet"
},
{

    title:"Thickness of table top",
    value:"25 mm"
}]


    return (
        <DtableWrap>
           
            <table className="table table-bordered">
  <thead>
    <tr>
  
      <th scope="col" colSpan="2">Dimentions</th>


    </tr>
  </thead>
  <tbody>
    

    {

tdata.map((item, i)=>{

return(
<tr key={i}>
<td>{item.title}</td>
<td>{item.value}</td>
</tr>) 

})
    }
    
  
  </tbody>
</table>


        </DtableWrap>
    )
}


const DtableWrap = styled.div`

width:80%;
border-radius:1rem;
overflow: auto;
  border-radius: 1rem;
  border: 1px solid  #c75213;
tr{

padding:1rem !important;

}
td, th{
font-size:1.3rem;
color:white;
border:1px solid #c75213 !important;
border-radius:10px !important;
padding:1rem !important;
}

table{

    border-spacing: 0;
  border-collapse: collapse;
  border-style: hidden;
  margin:0;

}

@media(max-width:960px){

    width:95%;
  
    td, th{
font-size:1rem;
color:white;
border:1px solid #c75213 !important;
border-radius:10px !important;
padding:0.5rem !important;
}


}



`
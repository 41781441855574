import React from 'react'
import styled from 'styled-components'
import { FaWhatsapp } from 'react-icons/fa';
import {Link} from 'gatsby'
export default function OrderBtn() {
    return (
    
        <BtnWrap>
      <Link to="https://wa.me/918971181818" className="abtn">
          
       <FaWhatsapp style={{transform:"translateY(-2px)", marginRight:"8px"}}/>Click here to Order</Link>

       
        </BtnWrap>
    )
}

const BtnWrap = styled.div`

padding:2rem 6rem;

position: relative;
background-color:transparent;
width:80%;
color:white;
border-radius:0.8rem;
margin: 3rem auto;
text-align:center;



.abtn{
    padding:1.1rem 0.3rem;
    position:absolute;
    color:#F39200;
    width:260px;
    margin:auto;
    top:30%;
    left:50%;
    text-align:center;
    font-weight:bold;
    transform:translate(-50%, -50%);
    background-color:white;
    border-radius:0.5rem;
    box-shadow: 0 3px 10px 1px rgba(0,0,0,0.2);
    font-size:1.5rem;
    cursor: pointer;


}

@media(max-width:960px){
    padding:2rem 1rem 1rem 1rem;

    .abtn{

width:80%;
font-size:1rem;

    }

    margin:2rem auto;

    


}
`
import React from 'react'
import styled from 'styled-components'
import mimage from '../../images/AustenTable/Study Table Dimensions.jpg'
import img1 from '../../images/AustenTable/Study Table Black Top View.jpg'
import img2 from '../../images/AustenTable/Study Table White Top View.jpg'
import DimentionTable from './dimentiontable'
export default function ImageSec() {
    return (
        <ImageSecWrap clasName="container-fluid">
            <div className="row no-gutters">
                <div className="col-sm-7">
                     <img src={mimage} alt="m" width="100%"/>
                     </div>

                     <div className="col-sm-5 px-sm-3 d-flex align-items-center justify-content-center   py-4 py-sm-0" style={{backgroundColor:"#000000"}}>
  
                       <DimentionTable/>


                     </div>

                <div className="col-sm-12 d-flex Color p-sm-5 ">

                    <h1 className="m-0 "> AUSTEN BLACK OXFORD CHERRY </h1>
                    <h1 className="m-0  text-white">AUSTEN WHITE NEW COUNTRY </h1>
                </div>
                <div className="col-sm-6 ">
                <h1 className="m-0 w"> AUSTEN BLACK OXFORD CHERRY </h1>
                    <img src={img1} alt="img1" width="100%"/>
                </div>
              <div className="col-sm-6">
              <h1 className="m-0 b text-white">AUSTEN WHITE NEW COUNTRY </h1>

              <img src={img2} alt="img2" width="100%"/>

              </div>
           
            </div>                

           
        </ImageSecWrap>
    )
}


const ImageSecWrap = styled.div`

.Color{
    

    align-items:center;
    justify-content:space-around;
    background: linear-gradient(135deg, rgba(247,247,247,0.9948354341736695) 50%, rgba(40,39,38,1) 50%);
 flex-direction:row;

}

.w, .b{

    display:none;
}
@media(max-width:960px){
    .Color{
display:none !important;
        
    }

    h1{

font-size:1.3rem;
padding:1rem;
width: 100%;
text-align:center;


}
.w{

    background-color:#f5f5f5;
    display:block;
    

}

.b{

    background-color:#000000;  
      display:block;
}

}
`